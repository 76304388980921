import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import strings from "../strings";
import "./HomeMenu.css";

function HomeMenu({showLinks, showLogIn, showSignUp}) {
	const menuItems = [
		{
			label: strings.features,
			url: "#features"
		},
		{
			label: strings.whyChooseULOI,
			url: "#why-choose-uloi"
		},
		{
			label: strings.testimonials,
			url: "#testimonials"
		},
		{
			label: strings.contacts,
			url: "#contacts"
		}
	];

	return (
		<Container fluid className="mt-3 mt-sm-4">
			<Navbar className="py-0" expand="lg" data-bs-theme="dark">
				<Navbar.Brand>
					<Link to="/">
						<img className="d-inline-block align-top me-3 me-xl-5" id="menu-logo" alt="ULOI" src="/images/logo_white.svg" />
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="mt-2">
					<Nav className="me-auto">
					{
						showLinks &&
							menuItems.map((menuItem, index) => (
								<Nav.Link key={index} className="menu-item menu-item-text me-2 me-lg-3 me-xl-4 me-xxl-5 my-auto py-2 user-select-none transparent-cursor" href={menuItem.url}>{menuItem.label}</Nav.Link>
							))
					}
					</Nav>
					<Nav>
					{
						showLogIn &&
							<Link className="menu-item menu-item-text me-0 me-xl-1 me-xxl-3 my-auto ps-0 py-2 user-select-none transparent-cursor" to="/login">{strings.logIn}</Link>
					}
					{
						showSignUp &&
							<Link id="signup-link" className="menu-item-text my-auto px-3 py-2 text-center user-select-none transparent-cursor" to="/signup">{strings.signUp}</Link>
					}
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</Container>
	);
};

export default HomeMenu;
