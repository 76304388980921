import {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import API from "../API";
// import ReCAPTCHA from "react-google-recaptcha";
import Separator from "./Separator";
import Utils from "../Utils";
import links from "../links";
import strings from "../strings";
import useHubSpot from "../hooks/useHubSpot";
import "./Landing.css";

const truncateText = (text, length) => ((text && typeof text === "string" && text.length > length) ? `${text.substring(0, length)}…` : text);

const FeatureCard = ({feature}) => {
	return (
		<Container className="feature-card px-3 pt-3 pb-5">
			<div className="feature-icon">
				<img alt="" src={feature.iconPath} />
			</div>
			<Row className="mt-5">
				<Col className="feature-title px-4 text-center user-select-none transparent-cursor">
					{feature.title}
				</Col>
			</Row>
			<Row className="mt-2">
				<Col className="opacity-75 px-4 pt-2 text-center user-select-none transparent-cursor">
					{truncateText(feature.description, 200)}
				</Col>
			</Row>
		</Container>
	)
};

const TestimonialCard = ({testimonial}) => {
	return (
		<Container className="testimonial-card py-4 d-flex flex-column">
			<Row className="px-2 px-sm-4">
				<Col className="d-flex flex-column my-3">
					<img alt="" className="testimonial-avatar" src={testimonial.avatar} />
				</Col>
				<Col>
					<div className="d-flex flex-column my-3 justify-content-end align-items-end">
						<img alt="" src="/images/quotes.svg" />
					</div>
				</Col>
			</Row>
			<Row className="my-3 px-2 px-sm-4 pb-2 flex-grow-1">
				<Col className="testimonial-comment lh-lg user-select-none transparent-cursor">
					&ldquo;{truncateText(testimonial.comment, 250)}&rdquo;
				</Col>
			</Row>
			<Row className="border-top px-2 px-sm-4">
				<Col className="testimonial-full-name pt-3 fw-bold user-select-none transparent-cursor">
					{testimonial.firstName} {testimonial.surname}
				</Col>
			</Row>
		</Container>
	)
};

function Landing({redirect}) {
	const firstNameRef = useRef();
	const surnameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const messageRef = useRef();
	const privacyPolicyRef = useRef();
	// const recaptchaRef = useRef();
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [errors, setErrors] = useState({});
	const location = useLocation();
	const navigate = useNavigate();
	useHubSpot();

	const logos = [
		{
			alt: "VITECO",
			iconPath: "/images/viteco.png",
			url: "https://www.vitecoelearning.eu/"
		},
		{
			alt: "MINERVA",
			iconPath: "/images/minerva.png",
			url: "https://minervaproject.eu"
		}
	];

	const features = [
		{
			title: strings.feature1Title,
			description: strings.feature1Text,
			iconPath: "/images/innovation.svg"
		},
		{
			title: strings.feature2Title,
			description: strings.feature2Text,
			iconPath: "/images/interface.svg"
		},
		{
			title: strings.feature3Title,
			description: strings.feature3Text,
			iconPath: "/images/tracking.svg"
		},
	];

	const testimonials = [
		{
			firstName: strings.testimonial1FirstName,
			surname: strings.testimonial1Surname,
			avatar: strings.testimonial1Avatar,
			comment: strings.testimonial1Comment
		},
		{
			firstName: strings.testimonial2FirstName,
			surname: strings.testimonial2Surname,
			avatar: strings.testimonial2Avatar,
			comment: strings.testimonial2Comment
		},
		{
			firstName: strings.testimonial3FirstName,
			surname: strings.testimonial3Surname,
			avatar: strings.testimonial3Avatar,
			comment: strings.testimonial3Comment
		}
	];

	const goUp = () => {
		const topContainer = document.getElementsByTagName("body")[0];
		if (topContainer) {
			topContainer.scrollIntoView({behavior: "smooth"});
		}
	};

	const goDown = () => {
		const topContainer = document.getElementById("contacts");
		if (topContainer) {
			topContainer.scrollIntoView({behavior: "smooth"});
		}
	};

	const clearFields = () => {
		firstNameRef.current.value = "";
		surnameRef.current.value = "";
		emailRef.current.value = "";
		phoneRef.current.value = "";
		messageRef.current.value = "";
		privacyPolicyRef.current.checked = false;
	};

	const trimFields = () => {
		firstNameRef.current.value = firstNameRef.current.value.trim();
		surnameRef.current.value = surnameRef.current.value.trim();
		emailRef.current.value = emailRef.current.value.trim();
		phoneRef.current.value = phoneRef.current.value.trim();
		messageRef.current.value = messageRef.current.value.trim();
	};

	const formValidation = () => {
		let errorMessages = {};
		let firstErrorField = null;

		if (firstNameRef.current.value.length === 0) {
			errorMessages.firstName = strings.requiredField;
			firstErrorField = firstNameRef;
		}

		if (surnameRef.current.value.length === 0) {
			errorMessages.surname = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = surnameRef;
			}
		}

		if (emailRef.current.value.length === 0) {
			errorMessages.email = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (emailRef.current.value.length > 0 && !Utils.isEmailValid(emailRef.current.value)) {
			errorMessages.email = strings.invalidEmailFormat;
			if (!firstErrorField) {
				firstErrorField = emailRef;
			}
		}

		if (phoneRef.current.value.length > 0 && !Utils.isPhoneNumberValid(phoneRef.current.value)) {
			errorMessages.phone = strings.invalidPhoneFormat;
			if (!firstErrorField) {
				firstErrorField = phoneRef;
			}
		}

		if (messageRef.current.value.length === 0) {
			errorMessages.message = strings.requiredField;
			if (!firstErrorField) {
				firstErrorField = messageRef;
			}
		}

		if (!privacyPolicyRef.current.checked) {
			errorMessages.privacyPolicy = strings.youMustAcceptThePrivacyPolicy;
			if (!firstErrorField) {
				firstErrorField = privacyPolicyRef;
			}
		}

		return {errorMessages, firstErrorField};
	}

	const sendContact = (event) => {
		event.preventDefault();
		setAlert("");
		setAlertType("");
		trimFields();

		const {errorMessages, firstErrorField} = formValidation();
		if (Object.keys(errorMessages).length > 0) {
			(firstErrorField ? firstErrorField : firstNameRef).current.focus();
			setErrors(errorMessages);
		} else {
			// const recaptchaToken = API.getReCAPTCHAToken(recaptchaRef);

			API.sendContact(/*recaptchaToken, */firstNameRef.current.value, surnameRef.current.value, emailRef.current.value, messageRef.current.value, phoneRef.current.value)
				.then((result) => {
					switch (result.statusCode) {
						case 201:
							setAlertType("success");
							setAlert(strings.yourMessageWasSentSuccessfully);
							clearFields();
							break;
						case 400:
						case 422:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				});
		}
	};

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (firstNameRef.current) {
			firstNameRef.current.focus();
		}
	};

	useEffect(() => {
		const onScroll = () => {
			const upButton = document.getElementById("up-button");

			if (window.scrollY >= (window.screen.height / 3)) {
				upButton.className = "d-block";
			} else {
				upButton.className = "d-none";
			}
		}

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	useEffect(() => {
		if (redirect) {
			navigate("/", {replace: true});
		}
	}, [navigate, location.pathname]);

	return (
		<Container fluid className="mb-0 mb-lg-3 justify-content-center text-white">
			<Row>
				<Col className="col-12 col-lg-8 col-xl-7">
					<Row className="mt-0 mt-lg-5">
						<Col className="text-center text-lg-start user-select-none transparent-cursor">
							<p className="uloi-title">
								{strings.universalLearningObjectIntegrator}
							</p>
							<p className="paragraph-content mt-4 mt-xl-5 opacity-75">
								{strings.uloiText}
							</p>
						</Col>
					</Row>
				</Col>

				<Col className="col-12 col-lg-4 col-xl-5 mt-3 mt-lg-0 text-center">
					<img alt="" id="rotating-wheel" src="images/wheel_rotating.gif" />
				</Col>
			</Row>

			<Row className="mt-4 text-center">
				<Col>
					<img alt="˅" id="down-button" src="/images/down.gif" onClick={goDown} />
				</Col>
			</Row>

			<Row className="mt-5 text-center">
				<Col id="chosen-by-customers" className="user-select-none transparent-cursor">
					{strings.uloiHasAlreadyBeenChosen}
				</Col>
			</Row>

			<Row className="mt-3">
				<Col className="d-flex flex-column flex-md-row mb-3 justify-content-center text-center">
				{
					logos.map((logo, index) => (
						<a key={index} className="mx-3 my-3 my-lg-4" href={logo.url} target="_blank" rel="noreferrer">
							<img alt={logo.alt} className="company-logo" src={logo.iconPath} />
						</a>
					))
				}
				</Col>
			</Row>

			<Row id="features" className="mt-4 justify-content-center">
				<Col className="col-12 col-lg-8 col-xl-7 text-center user-select-none transparent-cursor">
					<p className="paragraph-title fw-bold">{strings.features}</p>
					<p className="paragraph-content opacity-75">{strings.featuresText}</p>
				</Col>
			</Row>

			<Row className="mt-4">
			{
				features.map((feature, index) => (
					<Col key={index} className="col-12 col-lg-4 my-5 d-inline-flex">
						<FeatureCard feature={feature} />
					</Col>
				))
			}
			</Row>

			<Separator className="mt-4" />

			<Row id="why-choose-uloi" className="mt-4">
				<Col className="col-12 col-xl-6 pt-5">
					<Row className="justify-content-center">
						<Col className="fw-bold text-center text-xl-start paragraph-title user-select-none transparent-cursor">
							{strings.whyChooseULOI}
						</Col>
					</Row>
					<Row className="mt-3 mt-xl-0">
						<Col className="paragraph-content opacity-75 text-center text-xl-start user-select-none transparent-cursor">
							{strings.whyChooseULOIText}
						</Col>
					</Row>
				</Col>
				<Col className="col-12 col-xl-6 text-center">
					<img alt="" id="cubes" src="/images/cubes.gif" />
				</Col>
			</Row>

			<Row className="mt-4 justify-content-center">
				<Col className="col-12 col-lg-8 col-xl-7 fw-bold text-center paragraph-title user-select-none transparent-cursor">
					{strings.ifWeHaventConvincedYou}
				</Col>
			</Row>

			<Row id="testimonials" className="mt-4">
			{
				testimonials.map((testimonial, index) => (
					<Col key={index} className="col-12 col-lg-4 my-3 my-lg-2 d-inline-flex">
						<TestimonialCard testimonial={testimonial} />
					</Col>
				))
			}
			</Row>

			<Separator className="mt-5" />

			<Row id="contacts" className="mt-5 justify-content-center">
				<Col className="col-12 col-md-10 col-lg-6 col-xl-6 col-xxl-5">
					<Row id="contacts-paragraph-title" className="text-center text-lg-start fw-bold user-select-none transparent-cursor">
						<Col>
							{strings.doYouWantToKnowMore}
							<br />
							{strings.contactUs}
						</Col>
					</Row>
					{
						(alert) &&
							<Row className="mt-2">
								<Col>
									<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
								</Col>
							</Row>
					}
					<Form id="contact-form" className="mt-3" onSubmit={sendContact}>
						<Row>
							<Col className="col-12 col-xl-6">
								<Form.Group>
									<Form.Control type="text" id="landing-page-first-name" className={`py-2${(errors.firstName) ? " error-border" : ""}`} placeholder={`${strings.firstName}*`} ref={firstNameRef} onChange={() => setErrors({...errors, firstName: false})} />
									{(errors.firstName) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="landing-page-first-name">{errors.firstName}</Form.Label>}
								</Form.Group>
							</Col>
							<Col className="col-12 col-xl-6 mt-2 mt-xl-0">
								<Form.Group>
									<Form.Control type="text" id="landing-page-surname" className={`py-2${(errors.surname) ? " error-border" : ""}`} placeholder={`${strings.surname}*`} ref={surnameRef} onChange={() => setErrors({...errors, surname: false})} />
									{(errors.surname) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="landing-page-surname">{errors.surname}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<Form.Group>
									<Form.Control type="text" id="landing-page-email" className={`py-2${(errors.email) ? " error-border" : ""}`} placeholder={`${strings.email}*`} ref={emailRef} onChange={() => setErrors({...errors, email: false})} />
									{(errors.email) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="landing-page-email">{errors.email}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<Form.Group>
									<Form.Control type="text" id="landing-page-phone" className={`py-2${(errors.phone) ? " error-border" : ""}`} placeholder={strings.phone} ref={phoneRef} onChange={() => setErrors({...errors, phone: false})}/>
									{(errors.phone) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="landing-page-phone">{errors.phone}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<Form.Group>
									<Form.Control as="textarea" rows={5} id="landing-page-message" className={`py-2${(errors.message) ? " error-border" : ""}`} placeholder={`${strings.message}*`} ref={messageRef} onChange={() => setErrors({...errors, message: false})} />
									{(errors.message) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor" htmlFor="landing-page-message">{errors.message}</Form.Label>}
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col>
								<Form.Group className="d-flex justify-content-start">
									<Form.Check id="landing-page-accept-privacy-policy" className="d-inline user-select-none transparent-cursor" ref={privacyPolicyRef} onChange={() => setErrors({...errors, privacyPolicy: false})} />
									<Form.Label className="ms-2 mb-0 d-inline user-select-none transparent-cursor" htmlFor="landing-page-accept-privacy-policy" dangerouslySetInnerHTML={{__html: strings.formatString(strings.iAcceptThePrivacyPolicy, links.privacyPolicy)}}></Form.Label>
								</Form.Group>
								{(errors.privacyPolicy) && <Form.Label className="error-label mb-0 user-select-none transparent-cursor">{errors.privacyPolicy}</Form.Label>}
							</Col>
						</Row>
						<Row className="mt-2">
							<Col id="required-fields-text" className="user-select-none transparent-cursor">
								{strings.theFieldsMarkedWithAnAsteriskAreRequired}
							</Col>
						</Row>
						<Row className="mt-4">
							<Col className="d-flex justify-content-start">
								<Button type="submit" className="square-button user-select-none transparent-cursor">{strings.submit}</Button>
							</Col>
						</Row>
						{/* <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size="invisible" /> */}
					</Form>
				</Col>

				<Col className="col-12 col-md-10 col-lg-6 col-xl-5 mt-1 mt-md-3 mt-lg-5 px-auto px-lg-5 pt-5 pb-3">
					<Row className="flex-nowrap">
						<Col className="col-auto">
							<img alt="" className="contact-image" src="/images/phone.svg" />
						</Col>
						<Col className="col-9 p-0 transparent-cursor">
							<span className="contacts-block-title user-select-none">
								{strings.contactUs.toUpperCase()}
							</span>
							<p className="contacts-block-info m-0">
								<a className="text-white user-select-all" href="tel:+390957463250" target="_blank" rel="noreferrer">095.7463250</a>
								<span className="user-select-none"> {strings.or} </span>
								<a className="text-white user-select-none" href="https://api.whatsapp.com/send/?phone=390957463250" target="_blank" rel="noreferrer">WhatsApp</a>
							</p>
						</Col>
					</Row>

					<Row className="mt-5 flex-nowrap">
						<Col className="col-auto">
							<img alt="" className="contact-image" src="/images/building.svg" />
						</Col>
						<Col className="col-9 p-0 transparent-cursor">
							<span className="contacts-block-title user-select-none">
								{strings.operatingOffice.toUpperCase()}
							</span>
							<p className="contacts-block-info m-0 user-select-all">
								{strings.operatingOfficeAddressPart1}
								<br />
								{strings.operatingOfficeAddressPart2}
							</p>
						</Col>
					</Row>

					<Row className="mt-5 flex-nowrap">
						<Col className="col-auto">
							<img alt="" className="contact-image" src="/images/building.svg" />
						</Col>
						<Col className="col-9 p-0 transparent-cursor">
							<span className="contacts-block-title user-select-none">
								{strings.registeredOffice.toUpperCase()}
							</span>
							<p className="contacts-block-info m-0 user-select-all">
								{strings.registeredOfficeAddressPart1}
								<br />
								{strings.registeredOfficeAddressPart2}
							</p>
						</Col>
					</Row>
				</Col>
			</Row>

			<img id="up-button" className="d-none" alt="˄" src="/images/up.svg" onClick={goUp} />
		</Container>
	);
};

export default Landing;
