import {useContext, useEffect, useRef, useState} from "react";
import {Alert, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";
import {AccountContext} from "../pages/Account";
import API from "../API";
import LMSCard from "../components/LMSCard";
import {SessionContext} from "../App";
import strings from "../strings";
import "./Dashboard.css";

function Dashboard({redirect}) {
	const [isLoading, setIsLoading] = useState(true);
	const [lmsCards, setLMSCards] = useState([]);
	const [lmsCardsFiltered, setLMSCardsFiltered] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const searchTermRef = useRef();
	const navigate = useNavigate();
	const location = useLocation();
	const session = API.getSession();
	const role = (session && session.role) ? session.role : "";
	const [setAccountContext] = useContext(AccountContext);
	const setLoggedIn = useContext(SessionContext);

	const logout = () => API.logout().then(() => setLoggedIn(false));

	const closeAlert = () => {
		setAlert("");
		setAlertType("");

		if (searchTermRef.current) {
			searchTermRef.current.focus();
		}
	};

	const generateColour = (lmsID, name, url, type) => {
		const str = [lmsID, name, url, type].join("");
		let num = 0;

		for (let i = 0; i < str.length; i++) {
			num += str.charCodeAt(i);
		}

		return num % 10;
	};

	const search = () => {
		const filteredObjects = lmsCards.filter((lmsCard) => {
			const filteredKeys = ["name", "baseURL", "statementsType", "id", "adminLabel"];
			const filteredObject = Object.fromEntries(
				Object.entries(lmsCard).filter(([key]) => filteredKeys.includes(key))
			);
			return Object.values(filteredObject)
				.some((value) => value.toString().toLowerCase().includes(searchTermRef.current.value.toLowerCase()));
		});
		setLMSCardsFiltered(filteredObjects);
	};

	useEffect(() => {
		if (role === "superadmin") {
			API.getAdmins()
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 200:
								setAdmins(result.data);
								break;
							case 401:
								logout();
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								break;
							default:
						}
					}
				});
		}
	}, [])

	useEffect(() => {
		if (role !== "superadmin" || (Array.isArray(admins) && admins.length > 0)) {
			API.getLMSes()
				.then((result) => {
					if (result) {
						switch (result.statusCode) {
							case 200:
								if (Array.isArray(result.data)) {
									if (role === "superadmin") {
										for (let i = 0; i < result.data.length; i++) {
											const admin = admins.find((admin) => (admin.id === result.data[i].idAdmin));
											result.data[i].adminLabel = admin ? `${admin.username} (${admin.email})` : "";
										}
									}
									setLMSCards(result.data);
									setIsLoading(false);
								}
								break;
							case 401:
								logout();
								break;
							case 403:
								setAlertType("danger");
								setAlert(strings.unauthorisedRequest);
								break;
							case 500:
								setAlertType("danger");
								setAlert(strings.serverError);
								break;
							default:
						}
					}
				});
		}
	}, [admins]);

	useEffect(() => {
		setLMSCardsFiltered(lmsCards);
	}, [lmsCards]);

	useEffect(() => {
		setAccountContext({currentPage: strings.dashboard, breadcrumbs: [
			{
				label: strings.uloi,
				url: "/"
			},
			{
				label: strings.dashboard
			}
		]});
	}, [setAccountContext]);

	useEffect(() => {
		if (redirect) {
			navigate("/dashboard", {replace: true});
		}
	}, [navigate, location.pathname, redirect]);

	useEffect(() => {
		if (!isLoading && searchTermRef.current) {
			searchTermRef.current.focus();
		}
	}, [isLoading]);

	return (
		(isLoading) ?
			<Spinner animation="border" className="spinner position-absolute top-50 start-50" />
		:
			<Container id="dashboard-container">
				<Row className="mt-1">
					{
						(alert) &&
							<Col className="d-flex justify-content-center justify-content-md-start me-md-5">
								<Alert variant={alertType} id="dashboard-alert" className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
							</Col>
					}
					<Col className={`d-flex justify-content-center justify-content-md-end mx-1 my-auto ${(alert) && "mt-3 mt-md-auto"}`}>
					{
						(lmsCards.length > 0) &&
							<Form.Control type="text" id="search-term" placeholder={strings.search} ref={searchTermRef} onChange={search} />
					}
					</Col>
				</Row>
				<Row className="mt-3 justify-content-center justify-content-lg-start" xs={1} sm={1} md={2} lg={3} xl={4} xxl={5}>
				{
					lmsCardsFiltered.map((lmsItem, index) => (
						<Col key={index} className="mb-3 me-0 me-md-3">
							<LMSCard
								id={lmsItem.id}
								name={lmsItem.name} 
								url={lmsItem.baseURL}
								type={lmsItem.statementsType}
								adminID={lmsItem.idAdmin}
								adminLabel={lmsItem.adminLabel ?? ""}
								colourIndex={generateColour(lmsItem.id, lmsItem.name, lmsItem.baseURL, lmsItem.statementsType)}
								dashboard={{lmsCards, setLMSCards, setAlert, setAlertType}}
							/>
						</Col>
					))
				}
				</Row>
			</Container>
	);
}

export default Dashboard;
