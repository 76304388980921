import { useEffect } from "react";

export default function useHubSpot() {
	useEffect(() => {
		const script = document.createElement("script");
		script.type = "text/javascript";
		script.id = "hs-script-loader";
		script.async = true;
		script.defer = true;
		script.src = "//js-eu1.hs-scripts.com/145388714.js";
		document.body.appendChild(script); // Adds the script when loading the page

		return () => document.getElementById("hs-script-loader")?.remove(); // Removes the script when leaving the page
	}, []);
};
