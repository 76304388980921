import LocalizedStrings from "localized-strings";

const strings = new LocalizedStrings(Object.freeze({
	en: {
		actions: "Actions",
		add: "Add",
		administrator: "Administrator",
		administrators: "Administrators",
		allRightsAreReserved: "All rights are reserved",
		alreadyHaveAnAccount: "Already have an account?",
		areYouSureYouWantToProceed: "Are you sure you want to proceed with the requested action?",
		back: "Back",
		cancel: "Cancel",
		change: "Change",
		changeRemoveCredentials: "Change/remove credentials",
		companyYears: "© HT s.r.l. 2004-{0}",
		confirm: "Confirm",
		confirmPassword: "Confirm password",
		contactUs: "Contact us",
		contactUsWeWillBeHappyToHelpYou: "Contact us, we will be happy to help you.",
		contacts: "Contacts",
		cookiePolicy: "Cookie policy",
		createdByCompany: "Created by Moka Adv",
		credentialsTooltipText: "The user name and password are optional, and allow saving xAPI/SCORM.",
		dashboard: "Dashboard",
		delete: "Delete",
		deleteLMS: "Delete LMS",
		didntYoufindWhatYouWereLookingFor: "Didn't you find what you were looking for?",
		disable: "Disable",
		dontHaveAnAccountYet: "Don't have an account yet?",
		download: "Download",
		edit: "Edit",
		editAdminX: "Edit administrator - {0}",
		editLMS: "Edit LMS",
		editLMSX: "Edit LMS - {0}",
		email: "E-mail",
		emailNotRegistered: "E-mail not registered.",
		emailSuccessfullySent: "E-mail successfully sent.",
		enable: "Enable",
		enterANewPasswordForX: "Enter a new password for {0}",
		enterYourEmailToResetYourPassword: "Enter your e-mail to reset your password",
		error: "error",
		faq00_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq00_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq01_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq01_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq02_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq02_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq03_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq03_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq04_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq04_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq05_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq05_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq06_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq06_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq07_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq07_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq08_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq08_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq09_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq09_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq10_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq10_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faqs: "FAQs",
		feature1Text: "ULOI allows you to create captivating and innovative training courses.",
		feature1Title: "Innovation",
		feature2Text: "The user-friendly interface makes your life easier.",
		feature2Title: "Intuitive interface",
		feature3Text: "You will be able to effectively track your resources, such as virtual reality (VR) modules and serious games.",
		feature3Title: "Effective tracking",
		features: "Features",
		featuresText: "ULOI is able to integrate both 2D and 3D video games and any other type of learning object into your LMS platform.",
		fillInAllFieldsCorrectly: "Fill in all fields correctly",
		firstName: "First name",
		forgotPassword: "Forgot password?",
		goBack: "Go back",
		hiHowCanWeHelpYou: "Hi, how can we help you?",
		hide: "Hide",
		home: "Home",
		iAcceptThePrivacyPolicy: `I accept the <a href="{0}" target="_blank" rel="noreferrer">privacy policy</a>`,
		id: "ID",
		ifWeHaventConvincedYou: "If we haven't convinced you, read the opinion of those who have already tried ULOI",
		invalidEmailFormat: "Invalid e-mail format",
		invalidLink: "Invalid link",
		invalidPhoneFormat: "Invalid phone format",
		invalidURLFormat: "Invalid URL format",
		itSeemsYouAreLost: "It seems you are lost…",
		justNow: "Just now",
		keepReading: "Keep reading",
		linkExpired: "Link expired",
		lms: "LMS",
		lmsConflictNameText: "An LMS with the same name is already associated with this administrator.",
		lmsID: "LMS ID",
		logIn: "Log in",
		logInToYourULOIAccount: "Log in to your ULOI account",
		logOut: "Log out",
		loginFailed: "Log-in failed",
		logo: "Logo",
		loremIpsumDolorSitAmetConsectetur: "Lorem ipsum dolor sit amet consectetur", // TEMP
		manual: "Manual",
		message: "Message",
		metaDescriptionText: "ULOI is a data interchange module developed by HT Apps. ULOI allows LMS owners to track learning objects, such as video games. Read more.",
		name: "Name",
		newLMS: "New LMS",
		newUserName: "New user name",
		newPassword: "New password",
		next: "Next",
		noResults: "No results",
		notifications: "Notifications",
		numAdmins: "{0}/{1} administrators",
		ok: "OK",
		oopsThatsNotGood: "Oops! That's not good.",
		operatingOffice: "Operating office",
		operatingOfficeAddressPart1: "Via Duca degli Abruzzi, 30/36",
		operatingOfficeAddressPart2: "95127 Catania, Italy",
		or: "or",
		password: "Password",
		passwordIsRequiredWhenUserNameIsPresent: "Password is required when user name is present.",
		passwordIsTooWeak: "Password is too weak",
		passwordRequirementsText: "Passwords must contain at least:<ul><li>12 characters</li><li>1 uppercase letter</li><li>1 lowercase letter</li><li>1 number</li><li>1 special character: @.:,;'\"€£$+-_*=<>°#%?!&/\\()|[]{}^§~</li></ul>",
		passwordsDontMatch: "Passwords don't match",
		phone: "Phone",
		privacyPolicy: "Privacy policy",
		readOurReviews: "Read our reviews on {0}",
		registeredOffice: "Registered office",
		registeredOfficeAddressPart1: "Via Mario Sangiorgi, 37",
		registeredOfficeAddressPart2: "95129 Catania, Italy",
		remove: "Remove",
		requestFailed: "The request failed.",
		requiredField: "Required field",
		resetPassword: "Reset password",
		save: "Save",
		scorm: "SCORM",
		search: "Search",
		serverError: "Server error.",
		settings: "Settings",
		show: "Show",
		showUsers: "Show users",
		signUp: "Sign up",
		signUpNow: "Sign up now",
		signupAlreadyRegisteredText: "An administrator with this e-mail address is already registered.",
		signupSuccessfulText: "Your registration was successful.<br />Wait for your profile to be activated.",
		signupUnsuccessfulText: "Check your registration data again.",
		submit: "Submit",
		success: "Success",
		surname: "Surname",
		termsOfUse: "Terms of use",
		testimonial1Avatar: "/images/testimonial1_en.jpg",
		testimonial1Comment: "Thanks to ULOI, I added 2D and 3D games to our e-learning platform. This makes the training more fun and interesting. The easy way we connected things helped us make our classes better.",
		testimonial1FirstName: "Loraine",
		testimonial1Surname: "Nguyen",
		testimonial2Avatar: "/images/testimonial2_en.jpg",
		testimonial2Comment: "ULOI has changed the way we teach and how we teach. I liked how easy it was to use and how well it worked with the xAPI standard. I didn't think adding it to our training system would be so easy and fast!",
		testimonial2FirstName: "Ferdinand",
		testimonial2Surname: "Rossi",
		testimonial3Avatar: "/images/testimonial3_en.jpg",
		testimonial3Comment: "The discovery of ULOI saved me from having to completely alter our teaching approach. Our lessons were greatly enhanced by the ease and quick incorporation of novel training materials into our platform by ULOI.",
		testimonial3FirstName: "Sydne",
		testimonial3Surname: "Foster",
		testimonials: "Testimonials",
		theFieldsMarkedWithAnAsteriskAreRequired: "The fields marked with an asterisk are required",
		threeDeeAppId: "3D app ID",
		tutorial: "Tutorial",
		tutorialName: "tutorial-en.pdf",
		tutorialPath: "/tutorial-en.pdf",
		type: "Type",
		uloi: "ULOI",
		uloiHasAlreadyBeenChosen: "ULOI has already been chosen by customers all over the world.",
		uloiText: "Universal Learning Object Integrator (ULOI) of HT Apps is a SaaS platform designed to take your learning management system (LMS) experience to the next level in terms of versatility and effectiveness.",
		unauthorisedRequest: "Unauthorised request.",
		url: "URL",
		userID: "User ID",
		userManual: "User manual",
		userNameIsRequiredWhenPasswordIsPresent: "User name is required when password is present.",
		universalLearningObjectIntegrator: "Universal Learning Object Integrator",
		vatNoX: "VAT no. 04125270878",
		warning: "Warning",
		welcomeUser: "Welcome, {0}",
		whyChooseULOI: "Why choose ULOI",
		whyChooseULOIText: "Because it allows your LMS platforms to seamlessly integrate different modules with each other, such as 2D/3D games and web-based software, standardising the tracking mode with that of the SCORM and xAPI standards.",
		doYouWantToKnowMore: "Do you want to know more?",
		xapi: "xAPI",
		youtubeTutorialURL: "https://www.youtube.com/embed/GpoAvlvqcKM",
		youtubeULOIURL: "https://www.youtube.com/watch?v=vgDuPb_20Yc",
		youMustAcceptThePrivacyPolicy: "You must accept the privacy policy to continue",
		yourMessageWasSentSuccessfully: "Your message was sent successfully.",
		yourPasswordHasBeenResetSuccessfully: "Your password has been reset successfully."
	},
	it: {
		actions: "Azioni",
		add: "Aggiungi",
		administrator: "Amministratore",
		administrators: "Amministratori",
		allRightsAreReserved: "Tutti i diritti sono riservati",
		alreadyHaveAnAccount: "Hai già un account?",
		areYouSureYouWantToProceed: "Sei sicuro di voler procedere con l'azione richiesta?",
		back: "Indietro",
		cancel: "Annulla",
		change: "Cambia",
		changeRemoveCredentials: "Cambia/rimuovi credenziali",
		companyYears: "© HT s.r.l. 2004-{0}",
		confirm: "Conferma",
		confirmPassword: "Conferma password",
		contactUs: "Contattaci",
		contactUsWeWillBeHappyToHelpYou: "Contattaci, saremo felici di aiutarti.",
		contacts: "Contatti",
		cookiePolicy: "Politica sui cookie",
		createdByCompany: "Creato da Moka Adv",
		credentialsTooltipText: "Il nome utente e la password sono opzionali, e consentono il salvataggio di xAPI/SCORM.",
		dashboard: "Dashboard",
		delete: "Elimina",
		deleteLMS: "Elimina LMS",
		didntYoufindWhatYouWereLookingFor: "Non hai trovato quello che cercavi?",
		disable: "Disable",
		dontHaveAnAccountYet: "Non hai ancora un account?",
		download: "Scarica",
		edit: "Modifica",
		editAdminX: "Modifica amministratore - {0}",
		editLMS: "Modifica LMS",
		editLMSX: "Modifica LMS - {0}",
		email: "E-mail",
		emailNotRegistered: "E-mail non registrata.",
		emailSuccessfullySent: "E-mail inviata con successo.",
		enable: "Abilita",
		enterANewPasswordForX: "Inserisci una nuova password per {0}",
		enterYourEmailToResetYourPassword: "Inserisci la tua email per reimpostare la password",
		error: "errore",
		feature1Text: "ULOI ti permette di creare percorsi formativi accattivanti e innovativi.",
		feature1Title: "Innovazione",
		feature2Text: "L'interfaccia user-friendly ti semplifica la vita.",
		feature2Title: "Interfaccia intuitiva",
		feature3Text: "Potrai tracciare efficacemente le tue risorse, come moduli in virtual reality (VR) e serious game.",
		feature3Title: "Tracciamento efficace",
		features: "Caratteristiche",
		featuresText: "ULOI è in grado d'integrare all'interno della tua piattaforma FAD sia videogiochi 2D e 3D che qualsiasi altro tipo di learning object.",
		fillInAllFieldsCorrectly: "Compila tutti i campi correttamente",
		firstName: "Nome",
		forgotPassword: "Password dimenticata?",
		faq00_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq00_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq01_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq01_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq02_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq02_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq03_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq03_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq04_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq04_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq05_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq05_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq06_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq06_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq07_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq07_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq08_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq08_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq09_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq09_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq10_question: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faq10_answer: "Lorem ipsum dolor sit amet consectetur", // TEMP
		faqs: "Domande frequenti",
		goBack: "Torna indietro",
		hiHowCanWeHelpYou: "Ciao, come possiamo aiutarti?",
		hide: "Nascondi",
		home: "Home",
		iAcceptThePrivacyPolicy: `Accetto la <a href="{0}" target="_blank" rel="noreferrer">politica sulla privacy</a>`,
		id: "ID",
		ifWeHaventConvincedYou: "Se non ti abbiamo convinto, leggi il parere di chi ha già provato ULOI",
		invalidEmailFormat: "Formato e-mail non valido",
		invalidLink: "Link non valido",
		invalidPhoneFormat: "Formato telefono non valido",
		invalidURLFormat: "Formato URL non valido",
		itSeemsYouAreLost: "Sembra che tu ti sia perso…",
		justNow: "Proprio adesso",
		keepReading: "Continua a leggere",
		linkExpired: "Link scaduto",
		lms: "LMS",
		lmsConflictNameText: "Un LMS con lo stesso nome è già associato a questo amministratore.",
		lmsID: "ID LMS",
		logIn: "Accedi",
		logInToYourULOIAccount: "Accedi al tuo account ULOI",
		logOut: "Esci",
		loginFailed: "Accesso negato",
		logo: "Logo",
		loremIpsumDolorSitAmetConsectetur: "Lorem ipsum dolor sit amet consectetur", // TEMP
		manual: "Manuale",
		message: "Messaggio",
		metaDescriptionText: "ULOI è un modulo d'interscambio dati sviluppato da HT Apps. ULOI permette a chi possiede un LMS di tracciare i learning object, come i videogiochi. Leggi.",
		name: "Nome",
		newLMS: "Nuovo LMS",
		newUserName: "Nuovo nome utente",
		newPassword: "Nuova password",
		next: "Avanti",
		noResults: "Nessun risultato",
		notifications: "Notifiche",
		numAdmins: "{0}/{1} amministratori",
		ok: "OK",
		oopsThatsNotGood: "Ops! Non va bene.",
		operatingOffice: "Sede operativa",
		operatingOfficeAddressPart1: "Via Duca degli Abruzzi, 30/36",
		operatingOfficeAddressPart2: "95127 Catania, Italia",
		or: "o",
		password: "Password",
		passwordIsRequiredWhenUserNameIsPresent: "La password è richiesta quando è presente il nome utente.",
		passwordIsTooWeak: "La password è troppo debole",
		passwordRequirementsText: "Le passwords devono contenere almeno:<ul><li>12 caratteri</li><li>1 lettera maiuscola</li><li>1 lettera minuscola</li><li>1 numero</li><li>1 carattere speciale: @.:,;'\"€£$+-_*<>°#%?!&/\\()|[]{}^§~</li></ul>",
		passwordsDontMatch: "Le password non coincidono",
		phone: "Telefono",
		privacyPolicy: "Politica sulla privacy",
		readOurReviews: "Leggi le nostre recensioni su {0}",
		registeredOffice: "Sede legale",
		registeredOfficeAddressPart1: "Via Mario Sangiorgi, 37",
		registeredOfficeAddressPart2: "95129 Catania, Italia",
		requestFailed: "La richiesta ha fallito.",
		requiredField: "Campo obbligatorio",
		remove: "Rimuovi",
		resetPassword: "Reimposta la password",
		save: "Salva",
		scorm: "SCORM",
		search: "Cerca",
		serverError: "Errore del server.",
		settings: "Impostazioni",
		show: "Mostra",
		showUsers: "Mostra utenti",
		signUp: "Iscriviti",
		signUpNow: "Iscriviti ora",
		signupAlreadyRegisteredText: "Un amministratore con questo indirizzo di posta è già registrato.",
		signupSuccessfulText: "La tua iscrizione è avvenuta con successo.<br />Attendi l'attivazione del tuo profilo.",
		signupUnsuccessfulText: "Ricontrollare i dati di registrazione.",
		submit: "Invia",
		success: "Successo",
		surname: "Cognome",
		termsOfUse: "Termini d'uso",
		testimonial1Avatar: "/images/testimonial1_it.jpg",
		testimonial1Comment: "Grazie ad ULOI ho integrato nella nostra piattaforma FAD giochi 2D e 3D, rendendo il percorso di formazione più accattivante.",
		testimonial1FirstName: "Valerio",
		testimonial1Surname: "Trevisano",
		testimonial2Avatar: "/images/testimonial2_it.jpg",
		testimonial2Comment: "Sono rimasto colpito dalla facilità d'uso dell'ULOI e dalla compatibilità con lo standard xAPI. Grazie mille!",
		testimonial2FirstName: "Massimiliano",
		testimonial2Surname: "Greco",
		testimonial3Avatar: "/images/testimonial3_it.jpg",
		testimonial3Comment: "Se non avessi scoperto ULOI avrei dovuto cambiare approccio alla didattica. Invece l'Universal Learning Object Integrator ha reso l'integrazione di nuovi contenuti veramente semplice e rapida.",
		testimonial3FirstName: "Alda",
		testimonial3Surname: "Ferrari",
		testimonials: "Testimonial",
		theFieldsMarkedWithAnAsteriskAreRequired: "I campi contrassegnati con l'asterisco sono obbligatori",
		threeDeeAppId: "ID app 3D",
		tutorial: "Tutorial",
		tutorialName: "tutorial-it.pdf",
		tutorialPath: "/tutorial-it.pdf",
		type: "Tipo",
		uloi: "ULOI",
		uloiHasAlreadyBeenChosen: "L'ULOI è già stato scelto da Clienti in tutto il mondo.",
		uloiText: "L'Universal Learning Object Integrator (ULOI) di HT Apps è una piattaforma SaaS concepita per elevare l'esperienza del tuo learning management system (LMS) a un livello superiore, in quanto a versatilità ed efficacia.",
		unauthorisedRequest: "Richiesta non autorizzata.",
		url: "URL",
		userID: "ID utente",
		userManual: "Manuale d'uso",
		userNameIsRequiredWhenPasswordIsPresent: "Il nome utente è richiesto quando è presente la password.",
		universalLearningObjectIntegrator: "Universal Learning Object Integrator",
		vatNoX: "P.IVA 04125270878",
		warning: "Attenzione",
		welcomeUser: "Benvenuto/a, {0}",
		whyChooseULOI: "Perché scegliere ULOI",
		whyChooseULOIText: "Perché consente alle tue piattaforme LMS di integrare senza problemi moduli diversi tra di loro, come giochi 2D/3D e software web based, uniformando la modalità di tracciamento a quella degli standard SCORM e xAPI.",
		doYouWantToKnowMore: "Vuoi saperne di più?",
		xapi: "xAPI",
		youtubeTutorialURL: "https://www.youtube.com/embed/bDYLY1UWmRc",
		youtubeULOIURL: "https://www.youtube.com/watch?v=MeYE0HiKYjY",
		youMustAcceptThePrivacyPolicy: "Devi accettare la politica sulla privacy per continuare",
		yourMessageWasSentSuccessfully: "Il tuo messaggio è stato inviato con successo.",
		yourPasswordHasBeenResetSuccessfully: "La tua password è stata reimpostata con successo."
	}
}));

export default strings;
