import {Button, Col, Modal, Row} from "react-bootstrap";
import strings from "../strings";
import "./ConfirmationBox.css";

function ConfirmationBox({show, setShow, action}) {
	const ok = () => {
		action();
		close();
	}

	const close = () => setShow(false);

	return (
		<Modal id="confirmation-box-modal" show={show} onHide={close}>
			<Modal.Header>
				<Modal.Title className="user-select-none transparent-cursor">{strings.warning}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center">
				<Row>
					<Col className="user-select-none transparent-cursor">{strings.areYouSureYouWantToProceed}</Col>
				</Row>
				<Row className="mt-3">
					<Col>
						<Button type="button" className="standard-dark-button" onClick={ok}>{strings.confirm}</Button>
						<Button type="button" className="standard-light-button ms-3" onClick={close}>{strings.cancel}</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
}

export default ConfirmationBox;
