import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Pagination, Row, Spinner, Table } from "react-bootstrap";
import { AccountContext } from "../pages/Account";
import { SessionContext } from "../App";
import API from "../API";
import ConfirmationBox from "./ConfirmationBox";
import EditAdmin from "../components/EditAdmin";
import strings from "../strings";
import "./Admins.css";

const tableColumnNames = [
	strings.id,
	strings.name,
	strings.email,
	strings.actions
];

function Admins() {
	const [setAccountContext] = useContext(AccountContext);
	const setLoggedIn = useContext(SessionContext);
	const [isLoading, setIsLoading] = useState(true);
	const [editModalState, setEditModalState] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState(false);
	const [alert, setAlert] = useState("");
	const [alertType, setAlertType] = useState("");
	const [searchTerm, setSearchTerm] = useState("");
	const [admins, setAdmins] = useState([]);
	const [foundAdmins, setFoundAdmins] = useState(admins);
	const itemsPerPage = 5;
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(Math.ceil(admins.length / itemsPerPage));
	const searchTermRef = useRef(searchTerm);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const adminsOnCurrentPage = foundAdmins.slice(startIndex, endIndex);

	const logout = useCallback(() => API.logout().then(() => setLoggedIn(false)), [setLoggedIn]);

	const search = (e) => setSearchTerm(e.target.value);

	const closeAlert = () => {
		setAlert("");
		setAlertType("");
	};

	const remove = (id) => {
		API.deleteAdmin(id)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setAdmins(admins.filter((admin) => (admin.id !== id)));
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 400:
						case 404:
						case 422:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	};

	const changeAdminState = (id, state) => {
		API.changeAdminState(id, state)
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setAdmins(admins.map((admin) => ((admin.id === id) ? {...admin, verified: state} : admin)));
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 400:
						case 404:
						case 422:
							setAlertType("warning");
							setAlert(strings.requestFailed);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	}

	useEffect(() => {
		API.getAdmins()
			.then((result) => {
				if (result) {
					switch (result.statusCode) {
						case 200:
							setAdmins(result.data);
							setIsLoading(false);
							break;
						case 401:
							logout();
							break;
						case 403:
							setAlertType("danger");
							setAlert(strings.unauthorisedRequest);
							break;
						case 500:
							setAlertType("danger");
							setAlert(strings.serverError);
							break;
						default:
					}
				}
			});
	}, [logout]); // []

	useEffect(() => {
		const found = admins.filter((admin) => {
			const keysToFilter = ["id", "username", "email"];
			const filteredAdmin = Object.fromEntries(Object.entries(admin).filter(([key]) => keysToFilter.includes(key)));
			return Object.values(filteredAdmin)
				.some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()));
		});
		setFoundAdmins(found);

		setTotalPages(Math.ceil(found.length / itemsPerPage));

		if (searchTermRef.current !== undefined && searchTermRef.current !== searchTerm) {
			searchTermRef.current = searchTerm;
			setCurrentPage(1);
		}
	}, [admins, searchTerm]);

	useEffect(() => {
		if (totalPages > 0 && currentPage > totalPages) {
			setCurrentPage(totalPages);
		}
	}, [currentPage, totalPages]);

	useEffect(() => {
		setAccountContext({
			currentPage: strings.administrators,
			breadcrumbs: [
				{
					label: strings.uloi,
					url: "/"
				},
				{
					label: strings.administrators
				}
			]
		});
	}, [setAccountContext]);

	return (
		(isLoading) ?
			<Spinner animation="border" className="spinner" />
		:
			<>
				<Container id="admins-container">
					<Row className="py-2">
						<Col className="user-select-none transparent-cursor my-auto">
							<img alt="" src="/images/users.svg" />
							<span id="num-users" className="ms-2" dangerouslySetInnerHTML={{__html: strings.formatString(strings.numAdmins, `<span>${foundAdmins.length}</span>`, `<span>${admins.length}</span>`)}}></span>
						</Col>
						<Col className="d-flex justify-content-end">
							<Form.Control id="search-box" type="text" placeholder={strings.search} value={searchTerm} onChange={search} autoFocus={true} />
						</Col>
					</Row>
					{
						(alert) &&
							<Row className="mt-3">
								<Col>
									<Alert variant={alertType} className="mb-0 user-select-none transparent-cursor" dismissible={true} onClose={closeAlert}>{alert}</Alert>
								</Col>
							</Row>
					}
					<Row className="mt-3">
						<Col>
							<Table responsive striped>
								<thead>
									<tr>
									{
										tableColumnNames.map((columnName, index) => (
											<th key={index} className={`text-nowrap user-select-none transparent-cursor${(index === 0) ? " ps-3" : ""}`}>{columnName}</th>
										))	
									}
									</tr>
								</thead>
								<tbody>
								{
									(adminsOnCurrentPage.length === 0) ?
										<tr>
											<td className="py-3 text-center user-select-none transparent-cursor" colSpan="4">{strings.noResults}</td>
										</tr>
									:
										adminsOnCurrentPage.map((admin, index) => (
											<tr key={index}>
												<td className="ps-4 user-select-all">
													<span className={`${(admin.verified) ? "" : "opacity-40"}`}>{admin.id}</span>
												</td>
												<td className="user-select-all">
													<span className={`${(admin.verified) ? "" : "opacity-40"}`}>{admin.username}</span>
												</td>
												<td className="user-select-all">
													<span className={`${(admin.verified) ? "" : "opacity-40"}`}>{admin.email}</span>
												</td>
												<td className="d-flex">
													<Button type="button" className="edit-button" onClick={() => setEditModalState({id: admin.id, name: admin.username, email: admin.email})}>
														<img alt="" src="/images/edit.svg" />
														<span className="align-middle ms-2 d-none d-xl-inline">{strings.edit}</span>
													</Button>
													<Button type="button" className="delete-button ms-2 ms-xl-3" onClick={() => setDeleteModalState({id: admin.id})}>
														<img alt="" src="/images/delete.svg" />
														<span className="align-middle ms-2 d-none d-xl-inline">{strings.delete}</span>
													</Button>
													<Button type="button" className={`${(admin.verified) ? "disable-button" : "enable-button"} ms-2 ms-xl-3`} onClick={() => changeAdminState(admin.id, !admin.verified)}>
														<img alt="" src="/images/switch.svg" />
														<span className="align-middle ms-2 d-none d-xl-inline">{(admin.verified) ? strings.disable : strings.enable}</span>
													</Button>
												</td>
											</tr>
										))
								}
								</tbody>
							</Table>
							{
								(totalPages > 0) &&
									<Pagination className="d-flex justify-content-center">
										<Pagination.First className="user-select-none transparent-cursor" onClick={() => setCurrentPage(1)} />
										<Pagination.Prev className="user-select-none transparent-cursor" onClick={() => setCurrentPage((currentPage > 1) ? (currentPage - 1) : 1)} />
										{
											[...Array(totalPages)].map((_, index) => (
												<Pagination.Item className="user-select-none transparent-cursor cursor-pointer" key={index + 1} active={(index + 1) === currentPage} onClick={() => setCurrentPage(index + 1)}>
													{index + 1}
												</Pagination.Item>
											))
										}
										<Pagination.Next className="user-select-none transparent-cursor" onClick={() => setCurrentPage((currentPage < totalPages) ? (currentPage + 1) : totalPages)} />
										<Pagination.Last className="user-select-none transparent-cursor" onClick={() => setCurrentPage(totalPages)} />
									</Pagination>
							}
						</Col>
					</Row>
				</Container>

				<EditAdmin show={editModalState} setShow={setEditModalState} id={editModalState.id ?? 0} name={editModalState.name ?? ""} email={editModalState.email ?? ""} adminTable={{admins, setAdmins}} />
				<ConfirmationBox show={deleteModalState} setShow={setDeleteModalState} action={() => remove(deleteModalState.id)} />
			</>
	);
}

export default Admins;
