class Utils {
	static isPasswordStrong(password) {
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.:,;'"€£$+\-_*=<>°#%?!&/\\()|[\]{}^§~])[A-Za-z\d@.:,;'"€£$+\-_*=<>°#%?!&/\\|()[\]{}^§~]{12,255}$/;
		return regex.test(password);
	}

	static isEmailValid(email) {
		const regex = /^(?!.*\.{2})[^.@\s]([^@\s]*[^.@\s])?@[^\s@]+\.[^.\s]+$/;
		return regex.test(email);
	}

	static isPhoneNumberValid(phoneNumber) {
		const regex = /^((\+|0)\d{0,4}\.?)?\d{7,10}$/;
		return regex.test(phoneNumber);
	}

	static isURLValid(url) {
		if (typeof url === "string") {
			const ipv4Regex = /^(https?|ftp):\/\/(\d{1,3}\.){3}\d{1,3}(:[1-9]\d{0,4})?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/;
			const ipv6Regex = /^(https?|ftp):\/\/((([a-f\d]{0,4}?:){2,7}[a-f\d]{0,4})|(\[([a-f\d]{0,4}?:){2,7}[a-f\d]{0,4}\]))(:[1-9]\d{0,4})?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
			const hostnameRegex = /^(https?|ftp):\/\/([a-z\d]([a-z\d-]*[a-z\d])?\.){0,2}[a-z\d]([a-z\d-]*[a-z\d])?(:[1-9]\d{0,4})?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

			if (ipv4Regex.test(url) || ipv6Regex.test(url)) {
				return true;
			} else if (hostnameRegex.test(url)) {
				const protocolRegex = /^(https?|ftp):\/\//i;
				const finalPartRegex = /(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/; // Path, query string & fragment
				const hostnameOnly = url.replace(protocolRegex, "").replace(finalPartRegex, "");
	
				if (hostnameOnly.length <= 253) { // Max length
					return true;
				}
			}
		}
	
		return false;
	}

	static trimFields(formData) {
		if (formData) {
			return Object.fromEntries(
				Object.entries(formData).map(
					([key, value]) => ([key, (typeof value === "string") ? value.trim() : value])
				)
			);
		} else {
			return formData;
		}
	};
}

export default Utils;
